html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, hr, textarea, input,
abbr, article, aside, audio, canvas, datalist, details, figcaption, figure, footer, header, hgroup, mark, meter, nav, output, progress, section, summary, time, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-family: inherit;
	vertical-align: baseline;
	list-style: none;
}

html {-webkit-tap-highlight-color: rgba(0,0,0,0); -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;box-sizing: border-box;
	* {box-sizing: inherit; padding: 0; margin: 0;}
}

body {font-size: 100%; line-height: normal; line-height: 1;}
body a {text-decoration: none;}
body *:focus {outline: none;}
body, input, button, textarea, select {}

/* Block level elements */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {display: block;}

/* Tables still need 'cellspacing="0"' in the markup. */
table {border-collapse: collapse; border-spacing: 0;}
caption, th, td {padding: 0; text-align: left; vertical-align: middle;}

/* Remove possible quote marks (") from <q>, <blockquote>. */
blockquote:before, blockquote:after, q:before, q:after {content: "";}
blockquote, q {quotes: "" "";}
q {quotes: none;}
q:before, q:after {content: ''; content: none;}

/* Normal weight headings */
h1, h2, h3, h4, h5, h6 {font-weight: normal;}

/* Images & Copy */
img {-ms-interpolation-mode: bicubic;}
abbr[title] {border-bottom: 1px dotted;}
b, strong {font-weight: bold;}
blockquote {}
dfn, address {font-style: normal;}
mark {background: #ff0; color: #000;}
pre, code, kbd, samp {font-family: monospace, monospace; /* 1 */ _font-family: 'courier new', monospace; font-size: 1em;}
pre {white-space: pre; white-space: pre-wrap; word-wrap: break-word;}

small {font-size: 75%;}
sub, sup {font-size: 75%; line-height: 0; position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
nav ul, nav ol {list-style: none;}
dd {}

/* Clearfix */
.clearfix:after {content: "."; display: block; height: 0; clear: both; visibility: hidden;}
.clearfix {display: inline-block;}
* html .clearfix {height: 1%;}
.clearfix {display: block;}

/* Hidden */
.hidden             {width: 0; height: 0; margin: 0; padding: 0; display: none !important; visibility: hidden;}

/* Forms */
button {width: auto; height: auto; margin: 0; padding: 0; background: none;}
button, input.send, input.submit, input[type=submit], label, select {cursor: pointer;}
button, input, textarea {border: 0 none;}
fieldset {margin: 0 2px; padding: 0.35em 0.625em 0.75em;}
legend {*margin-left: -7px;}
textarea {overflow: auto; /* Remove default vertical scrollbar */ vertical-align: top; /* Add top vertical alignment */}

button, input, select, textarea {font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle;}
button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, input[type="file"] > input [type="button"]::-moz-focus-inner {border: none;}
button, input {line-height: normal; /* Define line-height as normal */ *overflow: visible;  /* Make overflow visible  */}
button,input[type="button"], input[type="reset"], input[type="submit"] {cursor: pointer; -webkit-appearance: button;}

input[type="checkbox"], input[type="radio"] {box-sizing: border-box;}
input[type="search"] {-webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box;}
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner, input::-moz-focus-inner {border: 0; padding: 0;}

/* Audio, Video & Canvas */
audio[controls], canvas, video {display: inline-block; *display: inline; *zoom: 1;}

/* Remove pseudo-padding on Buttons */
button::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, input[type="reset"]::-moz-focus-inner
{padding: 0 !important; border: 0 none !important;} 