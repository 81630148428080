
$primary: #306a9e;
$primary-darker: #2b5982;

$secondary: #88b4db;

$link-color: #9066f3;

$h1-color: $primary;
$h2-color:#8498a8;

$h3-bg-color:$primary;
$h4-bg-color:#8498a8;

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//
 
.body_1037 * { box-sizing: border-box; 
	h2 { margin: 0; padding: 0; }
}

// BODY
body { color: #222; font-size: 18px; font-family: Arial, Helvetica, sans-serif; text-align: center; line-height: 1.5; padding: 0px; margin: 0; position: relative; }

// LINKS
body a  { color: $link-color; text-decoration: none; transition: .5s; }
body a:hover, body a:focus { text-decoration: none; }

// LINKED IMAGES
a img, img a, img, p a img 	{ border: 0; border-bottom: 0; text-decoration: none; vertical-align: middle; }

// HEADINGS
h1,h2,h3,h4,h5,h6	{ font-weight: 500; }

h1 { font-size: 47px; margin: 0 0 20px 0; color: $h1-color; line-height:1.2; font-weight: 800; }
h2 { font-size: 35px; margin: 25px 0 20px 0; color: $h2-color; line-height:1.3; font-weight: 300; }
h3 { font-size: 20px; margin: 25px 0 10px 0; padding: 5px 10px 3px 10px; background: $h3-bg-color; color: #fff; }
h4 { @extend h3; background-position: 10px center; background-repeat: no-repeat; padding-left: 30px; background-color: $h4-bg-color; }

h4.open 			{ background-image: url(/i/design/expanding-faq/minus-white.png); }
h4.closed 			{ background-image: url(/i/design/expanding-faq/plus-white.png); }
h5 					{ font-size: 12px; }
h6, small 			{ font-size: 10px; }
h1+h2				{ margin-top:0; border:0; padding:0; }
h1+p, h2+p, h3+p	{ margin-top:0; }

// PARAGRAPHS AND LIST ITEMS
p { margin: 12px 0; padding: 0; }
li { margin: 2px 0; padding: 0 0 0 23px; background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px; }

// FORMS
form  { margin:0; padding:0; @extend .clearfix; }
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend  { border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, html input[type="button"], input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; *overflow: visible; /* 4 */ }
button[disabled], html input[disabled] { cursor: default; }
input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] 	{ -webkit-appearance: textfield; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
textarea { resize: vertical; overflow: auto; vertical-align: top; }
label { float: left; margin: 10px 0; width: 90px; display: inline; }
form .formfield { margin: 10px; float: left; display: inline; width: 200px; border: 1px solid #CCC; padding: 2px; }
form div { margin: 15px 0; display: block; clear: left; vertical-align: top; }
form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] { border: 1px solid #CCC; padding: 4px; width: 300px; margin: 0; background-color: #EFEFEF; }
input[type="text"]:focus, form div textarea:focus, form span textarea:focus, form div input[type="textarea"]:focus, form div input[type="password"]:focus { border-color: #000; }
form div span.label label, form div span.label,span.spacer,span.multiple span { width: 180px; float: left; margin: 0 0 0 0; }
form div span.multiple  { float: left; }
form div span.button    { padding-left: 180px; background: transparent; border: 0; }
form div span.button input	{ background: #666; color:#FFF; margin: 0; padding: 8px 10px; font-weight:bold; border: 0; }
form div span.button input:hover { background: #888; }
form div.clear { clear: both; padding-top: 5px; }

hr { height:1px;border-width:0;color:#f2f2f2;background-color:#f2f2f2; }

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning { width: 100%; background: #b20000; color: #fff; overflow: hidden; padding: 0 0 10px 0; transition: .5s; display: block }
#ie-warning p	{ margin: 5px auto; }
#ie-warning a { color: #fff; text-decoration:underline; cursor: pointer; }

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper { margin: 0 auto; text-align: center; max-width: 2000px; overflow: hidden; }
.wrapper { width: 990px; margin: 0 auto; height: 100%; @extend .clearfix; position: relative; }

.wrapper-inside	{ width: 1500px; max-width: 90%; margin: 0 auto; @extend .clearfix; position: relative; }

// fix home page wrapper called in t.asp
.body_1037 .wrapper-inside { width: 100%; max-width: 100%; padding: 0; }

// -----------------------------------//
// HEADER
// -----------------------------------//

header { padding: 0; position: relative;
	&:before { @include before(100%, 1px); background: rgba(#fff,.2); top: 75px; }
}

header > .wrapper { padding: 0; position: relative; z-index: 60; }

header h1 { margin: 0;  background: none; }

#logo-index { margin: 174px 0 0 0; padding: 0; float: left;
	a { color: #fff; font-size: 36px; margin: 30px 0 0 47px; line-height: 1; font-weight: 600;
		span { display: block; text-transform: uppercase; margin: 5px 0 0 0; text-indent: 47px; text-align: left; font-size: 18px; letter-spacing: 1px; font-weight: 500; }
		img#logo{ float: left; margin: -60px 0 0 0; }
	 }
 }

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock { position: absolute; right: 70px; top: 28px; z-index: 99;
	li { display: inline-block; @include box(25px); padding: 0; background: none; margin: 0 8px; vertical-align: middle;
		a { display: block; width: 100%; height: 100%; }

		#twitter-head { @include box(25px); overflow: hidden; z-index: 99;
			background: url(/i/design/tw-ic.png) no-repeat center;  
		 } 
	 }
 }

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element   { opacity: 0; }
		.translate { @include box(25px); overflow: hidden; background: url(/i/design/translate-ic.png) no-repeat center; z-index: 99; }
		.goog-te-gadget .goog-te-combo { padding: 20px; }

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS{ @include box(25px); background: url(/i/design/search-ic.png) no-repeat center; padding:0px;transition: all .3s ease; cursor: pointer; }

/* Search Style */
#search { position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.9); transition: all 0.5s ease-in-out; transform: translate(0px, -100%) scale(0, 0);opacity: 0;display: none; }

#search.open { transform: translate(0px, 0px) scale(1, 1); opacity: 1;  z-index: 9999999; display: block; }

#search input[type="search"] { position: absolute; top: 50%; left: 0; margin-top: -51px; width: 60%; margin-left: 20%; color: rgb(255, 255, 255);background: transparent; border-left: 0px solid transparent;border-right: 0px solid transparent; font-size: 40px; font-weight: 300;  text-align: center; outline: none; padding: 10px; }

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: $primary-darker;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box{ display: inline-block; background: #f1f1f1; padding: 5px 15px; font-size: 16px; border-radius: 4px; border: 1px solid #ccc; text-transform: uppercase; }
.search-numbers{ text-transform: uppercase; font-weight: bold; font-size: 12px;
	span{ background: #f1f1f1; padding: 3px 8px; margin: 0 3px;border-radius: 2px; border: 1px solid #ccc; }
 }

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder { width: 100%; margin: 0px auto; padding: 30px 0 30px 0; clear: both; text-align: left; @extend .clearfix; background: #f8f8f8; overflow: hidden; position: relative; z-index: 1; box-shadow: inset 100px 0 0 $primary-darker; 
	&:before { @include before(485px, 500px); background: url(/i/design/logo-bg.png) no-repeat center; background-size: contain; bottom: 65px; right: 60px; z-index: -1; }
}

// home page alternate
.body_1037 #mainholder { padding: 0; margin: 0; overflow: visible; box-shadow: none; background: none;
	&:before { @include before(485px, 723px); background: url(/i/design/logo-bg.png); bottom: 65px; right: 60px; z-index: -1; }
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec { padding: 70px 0 150px; width: 1365px; max-width: 95%; background: $primary; position: relative; margin: -100px auto 30px; z-index: 99; overflow: hidden; box-shadow: inset -200px 0 0 0 $primary-darker;

	&:before{ position: absolute; content: 'Welcome'; font-size: 200px; margin: 0; font-weight: 800; color: #fff; left: -40px; bottom: -5px; opacity: .08; }

	&:after { @include before(100%, 1px); background: rgba(#fff,.3); bottom: 65px; left: 0; }

	.wrapper { max-width: 80%; }

	h2 { color: #fff; font-size: 38px; margin: 0 0 30px 0; font-weight: 700;
		span { display: block; font-size: 18px; text-transform: uppercase; font-weight: normal; letter-spacing: .8px; }
	 }

	p { color: #fff; font-size: 16px; width: 80%; letter-spacing: .2px; }

	.headTeach { text-transform: uppercase; font-size: 14px; margin: 30px 0 0 0; }

	.read-more { display: block; text-align: center; position: absolute; bottom: -143px; left: 0; right: 0; height: 50px; font-size: 14px;line-height: 50px; width: 200px; margin: 0 auto; text-transform: uppercase; color: #fff; letter-spacing: 2.5px; font-weight: 600; transition: .3s;

		&:hover { color: $secondary; }
	}

 }

 // -----------------------------------//
// SOCIAL SEC
// -----------------------------------//

#news-social-sec { position: relative; box-shadow: inset 270px 0 0 0 $primary-darker; margin: 0 auto 30px;

	&:before{ @include before(49.2%, 100%); background: $primary; left: 0; top: 0; z-index: -1;  }
	&:after { @include before(50%, 1px); background: rgba(#fff,.5); bottom: 65px; left: 0; }

	#SideHeadingNews { width: 495px; float: left; height: 840px; padding: 70px 0 0 0; }

	.tw-cont { width: 495px; float: right; height: 840px; padding: 70px 0 0 0; position: relative;
		.tw { text-indent: 20px; margin: 35px 0 35px 0; color: $primary; position: relative; font-weight: 800; letter-spacing: -1px; font-size: 38px;
			&:after { @include before(131px, 111px); background: url(/i/design/tw-lg-ic.png) no-repeat center; top: -50px; right: 0; }
		 }

		.twitter-timeline { border: 1px solid #e0e0e0!important; margin: 0 0 0 15px; }

		.tw-more { width: 478px; background: $secondary; height: 66px; line-height: 69px; display: block; position: absolute; bottom: 0px; margin: 0 0 0 15px; text-align: center; color: #fff; text-transform: uppercase; font-size: 15px; font-weight: bold; letter-spacing: 1px; transition: .3s;

			&:hover { background: $primary; }
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column { width: 100%; max-width: 1200px; margin: 0 auto; padding: 50px; min-height:550px; text-align:left; background: rgba(255,255,255,.9);

	img{ max-width:100%; height:auto; }

	h1 { }
	h2 { }
 }

//Content 2
.Content2Column { width: 60%; margin: 0 0 0 10%; padding: 50px; min-height:550px; text-align:left; display: inline-block; float: left; background: rgba(255,255,255,.9);

	img{ max-width:100%; height:auto; }

	h1 { }
	h2 { }
 }

//Content 3
.Content3Column     { width: 430px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left;
	img{ max-width:100%; height:auto; }
 }

#mainholder { 
	tr,td { border: 1px solid #BFBFBF; }
	td { padding: 5px; }

	.tablenoborderpadded { border: 0!important;
		tr,td { border: 0px !important; }
	 }
 }

.HomeAlert .Content1Column { float: none; margin: 0 auto; }

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft { width: 250px; margin: 0; padding: 0; min-height:250px; text-align:left; float: left; }

.ColumnRight { width: 25%; margin: 0 30px 0 0; padding: 0; min-height:250px; text-align:left; display: inline-block; float: right; box-sizing: border-box; }

.ColumnLeft ul, .ColumnRight ul { margin: 0; padding: 0; list-style: none; }
.ColumnLeft nav ul a:link, .ColumnRight nav ul a:visited, .ColumnRight nav ul a:link, .ColumnRight nav ul a:visited { display: block; }
.ColumnLeft li { border-bottom: 1px solid #EEE; } // Hack to fix IE/Win's broken rendering of block-level anchors in lists
html > body .ColumnLeft li { border-bottom: none; } // Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading { margin: 0; padding: 0; text-align: center; }

// List
ul.SideList { margin:0; padding: 0; border: 0; list-style: none; text-align: left; }
ul.SideList li { margin:0; padding: 0; background: none; }

ul.SideList li a { margin: 0; padding: 0px; color: #000; transition: .5s; font-weight: normal;display: block; }
ul.SideList li a:hover { transition: .5s; text-decoration: none; }
ul.SideList li a.child { background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat; padding-left: 40px !important; }

// Date and Title
ul.SideList li p { margin: 0; padding: 0; }
ul.SideList p.date { float: left;text-align: center;font-size: 20px;line-height: 130%;transition: .5s;

	// Span = number
	span{ display: block;transition: .5s; }
 }
 
 ul.SideList p.title { float: right; width: 100%;font-size: 16px;transition: .5s;
	// Truncate
	span { overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; height: 43px; }
 }

// More links
.SideHeading a.more { display: inline-block;font-size: 18px;padding: 0px; transition: .5s;
	&:hover { transition: .5s; text-decoration: none; }
 }

// -----------------------------------//
// DIARY & NEWS
// -----------------------------------//

//Diary
#SideHeadingNews{ 

	h2 { margin: 0 0 60px 0; padding: 0; color: #fff; font-weight: 800; text-align: left; font-size: 60px; text-transform: uppercase; line-height: .8; letter-spacing: 2px;
		span { display: block; font-size: 120px; text-transform: none; font-weight: 800; letter-spacing: 0; }
	}

	ul.SideList { 
		li { width: 425px; height: 200px; display: block; position: relative; margin: 0 0 30px 0; transition: .3s; overflow: hidden;
			&:before { @include before(100%, 300px); background: url(/i/design/news-grad.png) repeat-x bottom left; bottom: 0; }

			img {width: 100%; height: 100%; object-fit: cover;}

			p.date { position: absolute; bottom: 70px; color: $secondary; padding: 0 40px 0 40px; font-size: 15px; font-weight: bold; }
			p.title { position: absolute; bottom: 20px; color: #fff; padding: 0 40px 0 40px; font-size: 15px; font-weight: bold; }
		 }

		 &:hover { 
			 li { box-shadow: 0 0 0 3px #fff; }
			 li:not(:hover){ opacity: .5; transform: scale(.95); box-shadow: none; }
		 }
	 }

	 	.wrap { position: absolute; bottom: 8px; width: 495px; left: -30px;

			.more { margin: 0 60px; font-size: 14px;line-height: 50px; width: 200px; margin: 0 auto; text-transform: uppercase; color: #fff; letter-spacing: 2.5px; font-weight: 600; transition: .3s;
				&:hover { color: $secondary; } }
		}
 }


//Related pages
#SideHeadingPages{ overflow: hidden; z-index: 1; padding: 5%; background-color: #2b5982; position: relative; box-shadow: inset 0 0 0 10px #306a9e;

	&:after { @include before(862px, 1286px); background: url(/i/design/logo-blow.png) top left; top: -10px; left: -80px; z-index: -1; opacity: .6; }


	h2 { display: block;text-align: center;font-size: 25px;padding: 20px 0;margin: 0; color: #fff; font-weight: 800; }

	ul.SideList { padding: 0 0 30px 0;;
		li { 
			a { padding: 7px 20px; font-size: 18px; color: #fff;
				&:hover { text-indent: 5px; background-color: rgba(255,255,255,.2); }
			 }
		 }
	 }
 }

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home { background: rgba(0, 0, 0, .4);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 8000;
	.message-modal{ position: absolute;top: calc(50% - 300px);left: calc(50% - 465px - 40px - 10px);width: 930px;height: auto; margin: 0 30px; padding: 20px;background: rgba(255, 255, 255, .9);
		.modal-content{ background: rgba(255, 255, 255, .9);background-position: -120px 50px ;box-shadow:inset 0 0 0 1px #fff;display: inline-block;width: 100%;height: 100%;text-align: center;
			.modal-text{ display: block; padding: 45px;
				p{ margin: 0; }
			 }
			a.close-modal{ position: absolute;top: 31px;right: 31px; }
		 }
	 }
 }

.hide-modal{ display: none !important; }

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards { width: 100%; overflow: hidden; background: #fff; padding: 20px 0;
	.CMSawards { overflow: hidden; width: 100%; height: 100%; margin: 0 auto !important; background: none !important;
		ul { margin: 0;
			li { background: none; padding: 0 5px; margin: 0 10px; display: inline-block;
			img { max-height: 70px; transform: none !important; }
			 }
		 }
	 }
 }

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer { clear: both; background: $secondary; padding: 30px 0 50px; position: relative; box-shadow: inset 270px 0 0 0 #fff, inset -460px 0 0 rgba(0,0,0,.05); z-index: 0;

	&:before{ @include before(572px, 248px); background: url(/i/design/footer-bg.png) no-repeat center; top: 0; right: 0; z-index: 1;  opacity: 1; }

	.wrapper { color: #fff; text-align: left; font-size: 18px; padding: 0 0 0 270px; position: relative;

		&:before{ position: absolute; content: 'Contact Us'; font-size: 120px; margin: 0; font-weight: 800; color: #fff; left: 220px; bottom: -90px; opacity: .1; }
	}

	.img { position: absolute; left: 5px; top: 5px; }
}

h2.footer-heading { padding: 0; color: #fff; margin: 0 0 35px -45px; font-size: 36px; font-weight: 800; }

p.address { position: relative; float: left;	margin: 0 0 30px 0;
	&:before { @include before(21px, 29px); background: url(/i/design/address-ic.png) no-repeat center; top: 0; left: -45px; } 
 }

p.telephone { position: relative; float: left; clear: left; 	margin: 0;
	&:before { @include before(25px, 26px); background: url(/i/design/telephone-ic.png) no-repeat center;   top: 0; left: -45px; } 
 }

footer a[href^="mailto:"]   { position: relative; color: #fff; float: right; margin: 0 150px 0 0;
	&:before { @include before(28px, 30px); background: url(/i/design/mail-ic.png) no-repeat center; top: -2px; left: -45px; } 
 }

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer { height: 60px; width: 100%; line-height: 60px; background: #fff; overflow: hidden; 
		.wrapper { padding: 0 0 0 250px;}

	p { font-size: 13px; color: #333; float: left; margin: 0; }
	a { color: #444; }

	#copyright { float: left; }
	#credit { float: right; }
 }

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: #fff;
$table-highlight-background-color: #2b5982;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p { text-align: center; }
$blockquote-background-color: #88b4db;
$blockquote-border-color: #88b4db;
$blockquote-color: #fff;
$blockquote-speech-mark-color: #fff;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content { background: none; }

// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast         { position: fixed; top: 0; left: 0; width: 100%; height: 40px; border-bottom: 1px solid #D4C790; font-family: sans-serif; font-size: 12px; font-weight: bold; line-height: 40px; color: #6d6647; text-align: center; background: #FAF4AF; }
#browserblast a       { color: green; text-decoration: underline; }
.unsupported-browser  { margin-top: 41px; }

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile      { width:100%; margin: 0; padding: 20px 0; background:#EFEFEF; border-top:1px solid #EBEBEB; border-bottom:1px solid #EBEBEB; text-align:center; color:#777; font-family: sans-serif; font-size: 12px; display:block; clear:both; }
#switch-mobile a    { text-decoration: underline; }