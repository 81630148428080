.body_1037 #slider { position: relative; min-height: 550px; height: 100vh;

  &:after { @include before(862px, 1286px); background: url(/i/design/logo-blow.png) top left; top: -10px; left: -80px; z-index: -1; opacity: .6; }

  .backstretch { position: relative;
  
    img  { top: 0px!important; animation: zoom 20s ease forwards infinite; }
  
    @keyframes zoom { 
      from { transform: scale(1); }
      to { transform: scale(1.2); }
    }
  }
}

#slider { width: 100%; position: relative; min-height: 350px; z-index: 1;
  &:before { @include before(100%, 5px); background: url(/i/design/grad.png) repeat-x top left; }
}

.backstretch { position: relative;

  // for top of page gradient
  &:before { @include before(100%, 450px); background: url(/i/design/gradient.png) repeat-x top left; }
  &:after { @include before(100%, 7px); background: url(/i/design/grad.png) repeat-x top left; bottom: 0; }

  img  { animation: none; }

}

.strapline { position: absolute; left: 0; right: 0; bottom: 300px; margin: 0 auto; text-align: center; color: #fff; }